/* eslint-disable no-console */
import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import MaterialTable, {MTableToolbar} from 'material-table'
import {makeStyles} from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
// import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'

import ProgramEditDialog from './ProgramEdit'
import ConfirmDialog from '../modals/ConfirmDialog'
import AudioPlayerDialog from '../modals/AudioPlayerDialog'
import Flag from '../layout/Flag'
import {users} from './UserList'

import {useAuth0} from '@auth0/auth0-react'
import {
  createProgram,
  deleteProgram,
  editProgram,
  fetchPrograms,
} from './ProgramServices'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  flag: {
    marginRight: theme.spacing(5),
  },
}))

const ProgramList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {getAccessTokenSilently} = useAuth0()

  const editRef = useRef()
  const dialogRef = useRef()
  const playerRef = useRef()

  const [state, setState] = useState({
    lang: 'kirundi',
    selectedRow: null,
    columns: [
      {title: 'Description', field: 'description'},
      {
        field: 'date',
        title: 'Date',
        render: (rawData) => (
          <span>{moment(rawData.date).format('DD/MM/YYYY HH:mm')}</span>
        ),
      },
      {title: 'Genre', field: 'genre'},
      {
        field: 'status',
        title: 'Status',
        render: (rawData) =>
          rawData.status === 'online' ? (
            <span style={{color: 'green'}}>{rawData.status}</span>
          ) : (
            <span>{rawData.status}</span>
          ),
      },
      {
        field: 'lastUpdatedBy',
        title: 'Last Updated By',
        render: (rawData) => {
          return <span>{users[rawData.lastUpdatedBy].name}</span>
        },
      },
    ],
  })

  const programs = useSelector((st) => st.program)
  const loading = useSelector((st) => st.crud.loading)

  useEffect(() => {
    fetchPrograms(getAccessTokenSilently, dispatch, state.lang)
  }, [state])

  const handleClickOpen = (payload) => {
    editRef.current.openDialog(payload)
  }

  const createNew = () => {
    const newAudio = {
      description: 'new audio ' + state.lang,
      audio: '',
      date: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      genre: 'kanguka',
      status: 'offline',
      audioSize: 0,
      shortUrl: '',
      lang: state.lang.slice(0, 2),
    }
    createProgram(getAccessTokenSilently, dispatch, state.lang, newAudio)
  }

  const handleDeleteConfirm = (payload) => {
    deleteProgram(
      getAccessTokenSilently,
      dispatch,
      state.lang,
      payload.id,
      payload.audio,
    )
  }

  const handleEditConfirm = (payload, file) => {
    editProgram(getAccessTokenSilently, dispatch, state.lang, payload, file)
  }

  const handleLangChange = (event) => {
    setState({...state, lang: event.target.value})
  }

  if (!programs || programs.length === 0) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <LinearProgress />
          <br />
          <p>Loading.... Please wait</p>
          <br />
          <LinearProgress color="secondary" />
        </Container>
      </div>
    )
  }

  if (loading > 0 && loading < 100) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <p>File uploading.... Please wait {parseInt(Number(loading), 10)}%</p>
          <progress id="uploader" value={loading} max="100"></progress>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <MaterialTable
        className={classes.content}
        title={'Audios'}
        columns={state.columns}
        data={programs}
        onRowClick={(evt, selectedRow) => {
          setState({...state, selectedRow})
          handleClickOpen(selectedRow)
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{padding: '0 0 0 10px'}}>
                <FormControl className={classes.formControl}>
                  <Select value={state.lang} onChange={handleLangChange}>
                    <MenuItem value={'kirundi'}> Kirundi </MenuItem>
                    <MenuItem value={'francais'}> Français </MenuItem>
                    <MenuItem value={'english'}> English </MenuItem>
                  </Select>
                </FormControl>
                <IconButton className={classes.flag} disabled>
                  <Flag lang={state.lang} />
                </IconButton>
                <IconButton
                  aria-label="Add"
                  color="primary"
                  onClick={createNew}>
                  <Icon color="primary">add_circle</Icon>
                </IconButton>
              </div>
            </div>
          ),
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Delete Program',
            onClick: (event, rawData) => {
              dialogRef.current.openDialog({
                id: rawData._id,
                description: rawData.description,
                audioPath: rawData.audioPath,
                audio: rawData.audio,
              })
            },
          },
          {
            icon: 'shop',
            tooltip: 'Play Audio',
            onClick: (event, rowData) => {
              playerRef.current.openDialog({
                description: rowData.description,
                audio: rowData.audio,
                video: rowData.videoUrl,
              })
            },
          },
        ]}
        options={{
          pageSize: 10,
          rowStyle: (rowData) => ({
            backgroundColor:
              state.selectedRow &&
              state.selectedRow.tableData.id === rowData.tableData.id
                ? '#EEE'
                : '#FFF',
          }),
          headerStyle: {
            backgroundColor: '#fafafa',
          },
        }}
      />
      <ProgramEditDialog ref={editRef} handleConfirm={handleEditConfirm} />
      <ConfirmDialog ref={dialogRef} handleConfirm={handleDeleteConfirm} />
      <AudioPlayerDialog ref={playerRef} />
    </div>
  )
}
export default ProgramList
