/* eslint-disable no-console */
const initState = {
  totalListiners: 0,
  audios: [],
  ipData: {},
  ipDataDetails: {},
  devices: {},
  timeseries: {},
  isLoading: true,
  message: 'Loading...',
  analytics: [],
  audioAnalytics: [],
}

const statsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_STATS_DASHBOARD_SUCCESS':
    case 'FETCH_STATS_LANG_SUCCESS':
    case 'FETCH_STATS_SUCCESS':
      return {
        ...state,
        analytics: action.payload.Items,
        isLoading: false,
      }
    case 'FETCH_STATS_BY_AUDIO_SUCCESS':
      return {
        ...state,
        audioAnalytics: action.payload.Items,
        isLoading: false,
      }
    case 'FETCH_AUDIO_SUCCESS':
      return {
        ...state,
        audios: action.payload.Items,
        isLoading: false,
      }
    case 'FETCH_STATS_ALL_AUDIO_SUCCESS':
      return {
        ...state,
        totalAudiosData: action.payload.totalAudiosData,
        isLoading: false,
      }
    case 'FETCH_STATS_DETAILS_SUCCESS':
      return {
        ...state,
        ipData: action.payload.data,
        isLoading: false,
      }

    case 'FETCH_IP_DETAILS_SUCCESS':
      return {
        ...state,
        ipDataDetails: action.payload,
        isLoading: false,
      }
    case 'FETCH_START':
      console.log('FETCH_START')
      return {
        ...state,
        isLoading: true,
      }

    case 'RESET_RESULT':
      console.log('RESET_RESULT')
      return {
        ...state,
        message: 'Loading...',
      }

    case 'FETCH_STATS_AUDIOS_SUCCESS':
      console.log('FETCH_STATS_AUDIOS_SUCCESS')
      console.log(action.payload)
      return {
        ...state,
        totalAudiosData: action.payload,
      }

    case 'FETCH_STATS_DEVICES_SUCCESS':
      console.log('FETCH_STATS_DEVICES_SUCCESS')
      return {
        ...state,
        devices: action.payload,
      }

    case 'FETCH_STATS_TIMESERIES_SUCCESS':
      console.log('FETCH_STATS_TIMESERIES_SUCCESS')
      return {
        ...state,
        timeseries: action.payload,
      }

    default:
      return state
  }
}

export default statsReducer
