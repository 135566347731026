import axios from 'axios'

// const apiUrl = 'http://localhost:3001'
const apiUrl = 'https://api.kanguka.info'

const axiosInstance = axios.create({
  baseURL: apiUrl,
})

export default axiosInstance
