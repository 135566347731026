import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AudioIcon from '@material-ui/icons/SettingsVoice'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import ListIcon from '@material-ui/icons/List'
import StatsIcon from '@material-ui/icons/TrendingUp'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import Collapse from '@material-ui/core/Collapse'
import {Link} from 'react-router-dom'
// import DateRangeIcon from '@material-ui/icons/DateRange'
// import DevicesIcon from '@material-ui/icons/Devices'
import PublicIcon from '@material-ui/icons/Public'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: '#FFF',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#20232a',
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menuLink: {
    color: 'hsla(0,0%,100%,.6)',
  },
  menuActive: {
    color: '#FFF',
  },
  sidebar: {
    backgroundColor: '#FEW',
  },
}))

const Sidebar = (props) => {
  const classes = useStyles()
  const [state, setState] = useState({
    selectedMenu: '/programs',
  })

  const menuChangeHandler = (menu) => {
    setState({...state, selectedMenu: menu})
  }

  const showUnderConstruction = () => {
    alert('Page under construction.')
  }

  const getClassName = (menu) => {
    return state.selectedMenu === menu ? classes.menuActive : classes.menuLink
  }

  const menus = [
    {
      name: 'Audios',
      link: '/programs',
      icon: () => (
        <AudioIcon
          onClick={() => {
            menuChangeHandler(menus[0].link)
          }}
          className={getClassName(menus[0].link)}
        />
      ),
    },
    {
      name: 'Categories',
      link: '/categories',
      icon: () => (
        <ListIcon
          onClick={() => {
            menuChangeHandler(menus[1].link)
          }}
          className={getClassName(menus[1].link)}
        />
      ),
    },
    {
      name: 'Announcements',
      link: '/announcements',
      icon: () => (
        <AnnouncementIcon
          onClick={() => {
            menuChangeHandler(menus[2].link)
          }}
          className={getClassName(menus[2].link)}
        />
      ),
    },
    {
      name: 'Gallery',
      link: '/gallery',
      icon: () => (
        <PhotoCameraIcon
          onClick={() => {
            menuChangeHandler(menus[3].link)
          }}
          className={getClassName(menus[3].link)}
        />
      ),
    },
  ]

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}>
      <div className={classes.toolbar} />
      <List>
        {menus.map((menu, index) => (
          <div key={index}>
            <Link
              to={menu.link}
              onClick={(e) => {
                if (
                  menu.link !== '/programs' &&
                  menu.link !== '/categories' &&
                  menu.link !== '/statistics' &&
                  menu.link !== '/gallery'
                ) {
                  e.preventDefault()
                  showUnderConstruction()
                }
              }}>
              <ListItem button>
                <ListItemIcon>
                  <menu.icon />
                </ListItemIcon>
                <ListItemText
                  primary={menu.name}
                  onClick={() => {
                    menuChangeHandler(menu.link)
                  }}
                  className={getClassName(menu.link)}
                />
              </ListItem>
            </Link>
            <Divider />
          </div>
        ))}
      </List>
      <List>
        <ListItem button>
          <ListItemIcon>
            <StatsIcon className={getClassName('/statistics')} />
          </ListItemIcon>
          <ListItemText
            primary={'Statistics'}
            className={getClassName('/statistics')}
          />
        </ListItem>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/statistics"
              onClick={(e) => {
                // e.preventDefault()
                // showUnderConstruction()
              }}>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <PublicIcon
                    onClick={() => {
                      menuChangeHandler('/statistics')
                    }}
                    className={getClassName('/statistics')}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Listeners"
                  onClick={() => {
                    menuChangeHandler('/statistics')
                  }}
                  className={getClassName('/statistics')}
                />
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon className={classes.menuLink} />
          </ListItemIcon>
          <ListItemText
            primary={'Logout'}
            onClick={props.signOut}
            className={classes.menuLink}
          />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default Sidebar
