import React, { useEffect, useState, useRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import MaterialTable, {MTableToolbar} from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'

import { fetchItems, editItem, deleteItem } from '../../store/actions/genericActions'
import AnnouncementEdit from './AnnouncementEdit'
import ConfirmDialog from '../modals/ConfirmDialog'
import PreviewDialog from '../modals/PreviewDialog'
import Flag from '../layout/Flag'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  content: {
    padding: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  flag: {
    marginRight: theme.spacing(5)
  }
}))
const currentPath = 'announcements'

const AnnouncementList = (props) => {
  const classes = useStyles()
  const {announcements, loading} = props

  const editRef = useRef()
  const dialogRef = useRef()
  const playerRef = useRef()

  const [state, setState] = useState({
    lang: 'kirundi',
    selectedRow: null,
    columns: [
      { title: "Title", field: "title" },
      { title: "Description", field: "description" },
      {
        field: 'date',
        title: 'Date',
        render: rawData => <span>{moment(rawData.date).format('DD/MM/YYYY HH:mm')}</span>
      },
      { title: "Type", field: "type" },
      {
        field: 'status',
        title: 'Status',
        render: rawData => rawData.status === 'online' ? <span style={{color: 'green'}}>{rawData.status}</span> : <span>{rawData.status}</span>
      }
    ]
  })

  useEffect(() => {
    props.fetchItems(currentPath, state.lang)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickOpen = (payload) => {
    editRef.current.openDialog(payload)
  }

  const createNew = () => {
    const newAnnouncement = {
      title: 'new announcement ' + state.lang,
      description: '',
      date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      type: 'text',
      status: 'offline'
    }
    props.editItem(currentPath, state.lang, newAnnouncement)
  }

  const handleDeleteConfirm = (payload) => {
    props.deleteItem(currentPath, state.lang, payload.id, payload.audioPath)
  }

  const handleEditConfirm = (payload) => {
    props.editItem(currentPath, state.lang, payload)
  }

  const handleLangChange = (event) => {
    setState({ ...state, lang: event.target.value})
    props.fetchItems(currentPath, event.target.value)
  }

  if (!announcements || Object.keys(announcements).length === 0) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <LinearProgress />
          <br />
          <p>Loading.... Please wait</p>
          <br />
          <LinearProgress color="secondary"/>
        </Container>
      </div>
    )
  }

  if (loading > 0 && loading < 100) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <p>File uploading.... Please wait {parseInt(Number(loading), 10)}%</p>
          <progress id="uploader" value={loading} max="100"></progress>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <MaterialTable
        className={classes.content}
        title={'Announcements'}
        columns={state.columns}
        data={announcements}
        onRowClick={((evt, selectedRow) => {
          setState({ ...state, selectedRow})
          handleClickOpen(selectedRow)
        })}
        components={{
          Toolbar: (tableProps) => (
            <div>
              <MTableToolbar {...tableProps} />
              <div style={{padding: '0 0 0 10px'}}>
                <FormControl className={classes.formControl}>
                  <Select
                    value={state.lang}
                    onChange={handleLangChange}
                  >
                    <MenuItem value={'kirundi'}> Kirundi </MenuItem>
                    <MenuItem value={'francais'}> Français </MenuItem>
                    <MenuItem value={'english'}> English </MenuItem>
                  </Select>
                </FormControl>
                <IconButton className={classes.flag} disabled>
                  <Flag lang={state.lang} />
                </IconButton>
                <IconButton aria-label="Add" color="primary" onClick={createNew}>
                  <Icon color="primary">add_circle</Icon>
                </IconButton>
              </div>
            </div>
          ),
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Delete Announcement',
            onClick: (event, rowData) => {
              dialogRef.current.openDialog({id: rowData.id, description: rowData.description, audioPath: rowData.audioPath})
            }
          },
          {
            icon: 'shop',
            tooltip: 'Play Video',
            onClick: (event, rowData) => {
              playerRef.current.openDialog({description: rowData.description, title: rowData.title, type: rowData.type})
            }
          },
        ]}
        options={{
          pageSize: 10,
          rowStyle: rowData => ({
            backgroundColor: (state.selectedRow && state.selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
          }),
          headerStyle: {
            backgroundColor: '#fafafa'
          }
        }}
      />
      <AnnouncementEdit ref={editRef} handleConfirm={handleEditConfirm} />
      <ConfirmDialog ref={dialogRef} handleConfirm={handleDeleteConfirm}/>
      <PreviewDialog ref={playerRef} />
    </div>)
}

const mapStateToProps = (state) => {
  return {
    announcements: state.announcement,
    auth: state.firebase.auth,
    loading: state.crud.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItems: (path, lang) => dispatch(fetchItems(path, lang)),
    editItem: (path, lang, payload, file) => dispatch(editItem(path, lang, payload, file)),
    deleteItem: (path, lang, id, filePath) => dispatch(deleteItem(path, lang, id, filePath))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AnnouncementList)
