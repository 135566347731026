export const users = {
  'auth0|60b615eb63019d0069dc51cd': {
    name: 'riamarius@gmail.com',
    nickname: 'riamarius',
    picture:
      'https://s.gravatar.com/avatar/c93a326598dda14088ebb1df61a31fdd?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fri.png',
  },
  'auth0|60bf70aa348edc006a20ecd7': {
    name: 'chrisndikumana@gmail.com',
    nickname: 'chrisndikumana',
    picture:
      'https://s.gravatar.com/avatar/60e78e84c4709f5ea41e0a7008e72ace?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fch.png',
  },
  'auth0|60bf712d612d820070a50e9a': {
    name: 'rmubamba@gmail.com',
    nickname: 'rmubamba',
    picture:
      'https://s.gravatar.com/avatar/80297296e7df5ced0912a11f983dd14a?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Frm.png',
  },
  'auth0|60bf86a2d2fc940071e24753': {
    name: 'gahungerearsele121@gmail.com',
    nickname: 'gahungerearsele121',
    picture:
      'https://s.gravatar.com/avatar/e032b604d97b79d1f1e00b1ad2514253?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fga.png',
  },
  'auth0|60bf866914208800680929b3': {
    name: 'niyongabonoella25@gmail.com',
    nickname: 'niyongabonoella25',
    picture:
      'https://s.gravatar.com/avatar/ade8d3aa1768e7d9c9a93403de2c4664?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fni.png',
  },
  'auth0|60bf86d870f91d00692fb38f': {
    name: 'sindemmy25@gmail.com',
    nickname: 'sindemmy25',
    picture:
      'https://s.gravatar.com/avatar/83d26d54e6f464f6b25ab896daf6cb4e?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fsi.png',
  },
  'auth0|60bf87001db0e90068ad882c': {
    name: 'carelodeluis@gmail.com',
    nickname: 'carelodeluis',
    picture:
      'https://s.gravatar.com/avatar/22b750e28f3058d1b86e9c4735ad8373?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fca.png',
  },
  'auth0|63d7bcf7b124da2e44ba8f5a': {
    name: 'bigirimanaboris@gmail.com',
    nickname: 'bigirimanaboris',
    picture:
      'https://s.gravatar.com/avatar/22b750e28f3058d1b86e9c4735ad8373?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fbb.png',
  },
}
