/* eslint-disable no-alert */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Typography,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {useAuth0} from '@auth0/auth0-react'

import Virtualize from './Autocomplete'
import EnhancedTable from './StatisticsTable'
import {
  fetchAnalyticsByAudio,
  fetchAnalyticsByDate,
  fetchAudios,
} from './StatisticsServices'
import {useStyles} from './StatisticsStyle'

const StatisticsHome = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {getAccessTokenSilently} = useAuth0()

  const [loading, setLoading] = useState(false)
  const isLoading = useSelector((state) => state.stats.isLoading)
  const audiosData = useSelector((state) => state.stats.audios)
  const [range, setRange] = useState('Today')
  // const [lang, setLang] = useState('All')
  const [audios, setAudios] = useState(null)
  const [selectedAudio, setSelectedAudio] = useState('')
  const [view, setView] = useState('byLang')
  const [selectedDate, setSelectedDate] = useState(null)

  // const handleLangChange = (event) => {
  //   setLang(event.target.value)
  //   // fetchItems(currentPath, event.target.value)
  // }

  // const getLang = (l) => {
  //   if (l === 'Francais') {
  //     return 'fr'
  //   }
  //   if (l === 'English') {
  //     return 'en'
  //   }
  //   if (l === 'Kirundi') {
  //     return 'ki'
  //   }
  //   return 'all'
  // }

  useEffect(() => {
    if (range !== 'Custom') {
      fetchAnalyticsByDate(
        getAccessTokenSilently,
        dispatch,
        range.toLocaleLowerCase(),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range])

  useEffect(() => {
    if (selectedDate !== null) {
      fetchAnalyticsByDate(
        getAccessTokenSilently,
        dispatch,
        moment(selectedDate).format('YYYY-MM-DD'),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  useEffect(() => {
    if (view === 'byAudios' && audiosData.length === 0) {
      fetchAudios(getAccessTokenSilently, dispatch)
    }
  }, [view])

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (audiosData && audiosData.length > 0) {
      const data = []
      for (let index = 0; index < audiosData.length; index++) {
        const element = audiosData[index]
        const parsedElement = element['uri']
        if (parsedElement !== '/audios') {
          data.push(parsedElement)
        }
      }
      setAudios(data)
    }
  }, [audiosData])

  useEffect(() => {
    if (selectedAudio && selectedAudio !== '') {
      fetchAnalyticsByAudio(getAccessTokenSilently, dispatch, selectedAudio)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAudio])

  //
  const isLangView = view === 'byLang'
  const isAudiosView = view === 'byAudios'
  const isAudiosAvailable = audiosData && audiosData.length > 0
  const isCustom = range === 'Custom'

  //
  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleTypeChange = (event) => {
    setView(event.target.value)
  }

  const handleRangeChange = (event) => {
    setRange(event.target.value)
  }

  // style={{height: 'calc(100vh - 100px)', width: '100%'}}
  return (
    <Paper className={classes.root}>
      <Typography component="div" style={{height: '100%', width: '100%'}}>
        <Box className={classes.title}>
          <Typography variant="h5" style={{marginBottom: 10}}>
            Audio Listeners{' '}
          </Typography>
          <Typography style={{marginTop: 10, marginBottom: 10}}>
            {moment('2021-07-21').format('LL')} to {moment().format('LL')}
          </Typography>
        </Box>
        <FormControl component="fieldset" style={{marginTop: 10}}>
          {/* <FormLabel component="legend">Gender</FormLabel> */}
          <RadioGroup
            aria-label="type"
            name="type1"
            value={view}
            onChange={handleTypeChange}
            row>
            <FormControlLabel
              value="byLang"
              control={<Radio />}
              label="By Date"
            />
            <FormControlLabel
              value="byAudios"
              control={<Radio />}
              label="By Audio"
            />
          </RadioGroup>
        </FormControl>
        <Grid container spacing={3}>
          {isLangView && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    width: '400px',
                    paddingLeft: 15,
                  }}>
                  {/* <Typography>Range:{'   '}</Typography> */}
                  <FormControl className={classes.formControl}>
                    <Select
                      value={range}
                      onChange={handleRangeChange}
                      style={{width: 150, marginTop: 5}}>
                      <MenuItem value={'All'}> All </MenuItem>
                      <MenuItem value={'Today'}> Today </MenuItem>
                      <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                      <MenuItem value={'Weekly'}>Last 7 days</MenuItem>
                      <MenuItem value={'Monthly'}>Last 30 days</MenuItem>
                      <MenuItem value={'Custom'}>Custom date</MenuItem>
                    </Select>
                  </FormControl>
                  {isCustom && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date"
                        label="Date"
                        format="dd/MM/yyyy"
                        minDate={moment('2021-07-21').toDate()}
                        maxDate={moment().toDate()}
                        value={selectedDate}
                        className={classes.textField}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </Box>
                {/* <Box
                  display="flex"
                  flexDirection="row"
                  p={1}
                  justifyContent="space-between">
                  <Box fontWeight={500} m={1}>
                    Languages
                  </Box>
                </Box>
                <FormControl className={classes.formControl}>
                  <Select
                    value={lang}
                    onChange={handleLangChange}
                    style={{width: 100}}>
                    <MenuItem value={'All'}> All </MenuItem>
                    <MenuItem value={'Kirundi'}> Kirundi </MenuItem>
                    <MenuItem value={'Francais'}> Français </MenuItem>
                    <MenuItem value={'English'}> English </MenuItem>
                  </Select>
                </FormControl> */}
              </Paper>
              {!loading && (
                <EnhancedTable
                  range={range}
                  isAudiosView={false}
                  audio={selectedAudio}
                />
              )}
            </Grid>
          )}
          {isAudiosView && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Box
                  display="flex"
                  flexDirection="row"
                  p={1}
                  justifyContent="flex-start"
                  height={300}>
                  <Box fontWeight={500} m={1} marginRight={10}>
                    Audios {audios && `(${audios.length})`}
                  </Box>

                  {isAudiosAvailable && (
                    <FormControl
                      className={classes.formControl}
                      style={{width: '90%'}}>
                      <Virtualize
                        data={audios}
                        onChange={(event) => {
                          if (event.indexOf('<span') < 0) {
                            setSelectedAudio(event)
                          }
                        }}
                      />
                    </FormControl>
                  )}
                </Box>
              </Paper>
              {!loading && (
                <EnhancedTable
                  range={range}
                  isAudiosView={true}
                  audio={selectedAudio}
                />
              )}
            </Grid>
          )}
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center">
          {loading && (
            <CircularProgress className={classes.number} color="secondary" />
          )}
        </Box>
      </Typography>
    </Paper>
  )
}

export default StatisticsHome
