/* eslint-disable no-alert */
/* eslint-disable no-console */
import axiosInstance from '../../config/api'
import jwtDecode from 'jwt-decode'

export const fetchPictures = async (getAccessTokenSilently, dispatch) => {
  try {
    const token = await getAccessTokenSilently()
    // const payload = JSON.stringify({
    //   lang: lang.slice(0, 2),
    //   limit: 30,
    // })
    const config = {
      method: 'post',
      url: '/api/v1/gallery/list',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {},
    }
    const response = await axiosInstance(config)
    const responseData = await response
    dispatch({
      type: `FETCH_GALLERY_SUCCESS`,
      payload: responseData.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const createPicture = async (
  getAccessTokenSilently,
  dispatch,
  payload,
) => {
  try {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)
    const data = JSON.stringify(payload)

    payload['lastUpdatedBy'] = decoded.sub

    const config = {
      method: 'post',
      url: '/api/v1/gallery/create',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'CREATE_GALLERY_SUCCESS',
    })
    fetchPictures(getAccessTokenSilently, dispatch)
  } catch (error) {
    console.log(error)
  }
}

export const editPictureWithFile = async (
  getAccessTokenSilently,
  dispatch,
  payload,
) => {
  try {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)
    payload['lastUpdatedBy'] = decoded.sub

    const data = JSON.stringify(payload)
    const config = {
      method: 'put',
      url: `/api/v1/gallery/update/${payload.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'EDIT_GALLERY_SUCCESS',
    })
    fetchPictures(getAccessTokenSilently, dispatch)
  } catch (error) {
    console.log(error)
  }
}

export const editPicture = async (
  getAccessTokenSilently,
  dispatch,
  payload,
  file = null,
) => {
  const regex = new RegExp(/^[a-z0-9_\.]+(.jpe?g|png|webp)$/)
  if (file) {
    if (!regex.test(file.name)) {
      alert('Filename is invalid. Please check again.')
    } else {
      const formData = new FormData()
      formData.append('file', file)
      const token = await getAccessTokenSilently()

      const config = {
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        url: '/api/v1/asset/upload',
        onUploadProgress: (ev) => {
          const progress = (ev.loaded / ev.total) * 100
          dispatch({
            type: 'START_UPDATE',
            payload: progress,
          })
        },
      }
      const response = await axiosInstance(config)
      const responseData = await response

      const url = `https://cdn.kanguka.info/${
        responseData.data.url.split('net/')[1]
      }`
      payload['photo'] = url
      payload['lastUpdatedOn'] = String(new Date().getTime())
      editPictureWithFile(getAccessTokenSilently, dispatch, payload)
    }
  } else {
    try {
      const token = await getAccessTokenSilently()
      const decoded = jwtDecode(token)

      payload['lastUpdatedBy'] = decoded.sub

      const data = JSON.stringify(payload)
      const config = {
        method: 'put',
        url: `/api/v1/gallery/update/${payload.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      }
      const response = await axiosInstance(config)
      const responseData = await response
      console.log(responseData.status)
      dispatch({
        type: 'EDIT_GALLERY_SUCCESS',
      })
      fetchPictures(getAccessTokenSilently, dispatch)
    } catch (error) {
      console.log(error)
    }
  }
}

export const deletePicture = async (
  getAccessTokenSilently,
  dispatch,
  id,
  filePath,
) => {
  if (filePath) {
    console.log('================filePath====================')
    console.log(filePath)
    console.log('====================================')

    const token = await getAccessTokenSilently()

    const deleteUrl = `//dgpa7m9705i5r.cloudfront.net/${
      filePath.split('.info/')[1]
    }`

    const data = JSON.stringify({
      assetUrl: deleteUrl,
    })

    const config = {
      method: 'delete',
      url: '/api/v1/asset/delete',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
  }
  try {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)

    const data = JSON.stringify({
      id: id,
      lastUpdatedBy: decoded.sub,
    })
    const config = {
      method: 'delete',
      url: `/api/v1/gallery/delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'DELETE_GALLERY_SUCCESS',
    })
    fetchPictures(getAccessTokenSilently, dispatch)
  } catch (error) {
    console.log(error)
  }
}
