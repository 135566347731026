/* eslint-disable no-console */
const axios = require('axios')

const apiUrl = 'http://localhost:3001'

axios.interceptors.request.use(
  (config) => {
    const {origin} = new URL(config.url)
    const allowedOrigins = [apiUrl]
    const token = localStorage.getItem('token')
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

const getPrefix = (path) => {
  let prefix
  switch (path) {
    case 'programs':
    case 'gallery':
      prefix = '-kang'
      break
    case 'categories':
      prefix = '-cat'
      break
    case 'announcements':
      prefix = '-anc'
      break
    default:
      prefix = ''
      break
  }
  return prefix
}
export const fetchItems = (path, lang) => {
  return (dispatch, getState, {getFirebase}) => {
    // make async call
    const firebase = getFirebase()
    firebase
      .ref(`${path}/${lang}`)
      .limitToLast(100)
      .on('value', (snapshot) => {
        dispatch({
          type: `FETCH_${path.toUpperCase()}_SUCCESS`,
          payload: snapshot.val(),
        })
      })
  }
}

export const editItem = (path, lang, payload, file = null) => {
  return (dispatch, getState, {getFirebase}) => {
    // make async call
    const firebase = getFirebase()
    const apiKey = 'AIzaSyDkAFQWRzbBDf6Ejc703bLoPk96GT-W-94' // getState().firebase.auth.apiKey
    let itemPath = ''
    // new
    if (!payload.id) {
      itemPath = path + '/' + lang
      const ref = firebase.ref(itemPath)
      // grab current snap
      var promise = new Promise((resolve, reject) => {
        firebase.ref(itemPath).on('value', (snapshot) => {
          let currentSnap = snapshot.val()
          delete payload['id']
          delete payload['open']

          const key = String(new Date().getTime())
          currentSnap[`${getPrefix(path)}${lang}${key}`] = payload
          if (currentSnap) {
            resolve(currentSnap)
          } else {
            reject(Error('Error while fetching current programs snapshot!'))
          }
        })
      })
      promise.then((data) => {
        ref.set(data).then(() => {
          dispatch({
            type: 'CREATE_PROGRAM_SUCCESS',
          })
          fetchItems(path, lang)
        })
      })
    } else {
      // edit
      itemPath = path + '/' + lang + '/' + payload.id
      delete payload['open']

      // check if data exist
      firebase.ref(path).once('value', (snap) => {
        const dbState = {}
        const languages = ['francais', 'kirundi', 'english']
        const othersLang = languages.filter((item) => item !== lang)
        dbState['francais'] = snap.child('francais').hasChild(payload.id)
        dbState['kirundi'] = snap.child('kirundi').hasChild(payload.id)
        dbState['english'] = snap.child('english').hasChild(payload.id)

        if (
          snap.exists() &&
          (dbState[othersLang[0]] || dbState[othersLang[1]])
        ) {
          // console.log('current lang state: ' + dbState[lang])
          // console.log(dbState[othersLang[1]])
          // console.log(dbState)
          console.log(
            'STOP! There is a duplicate in ' +
              othersLang[0] +
              ' or ' +
              othersLang[1],
          )
        } else {
          // upload file
          if (file) {
            if (path === 'gallery') {
              const storageRef = firebase.storage().ref()
              const fileRef = storageRef.child(file.name) // path + '/' + lang + '/' + file.name
              const uploadTask = fileRef.put(file)
              uploadTask.then(() => {
                fileRef.getDownloadURL().then((url) => {
                  payload['photo'] = url
                  payload['photoPath'] = fileRef.location.path_
                  return firebase
                    .ref(itemPath)
                    .update(payload)
                    .then(() => {
                      dispatch({
                        type: 'END_UPDATE',
                      })
                      fetchItems(path, lang)
                    })
                })
              })
              uploadTask.on(
                'state_changed',
                (snapshot) => {
                  // observe state change events such as progress, pause, and resume
                  // get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  dispatch({
                    type: 'START_UPDATE',
                    payload: progress,
                  })
                },
                (error) => {
                  // handle unsuccessful uploads
                  console.log(error)
                },
              )
            } else {
              const formData = new FormData()
              formData.append('audio', file)

              axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                data: formData,
                url: 'https://cdn.kanguka.net/api/upload',
                onUploadProgress: (ev) => {
                  const progress = (ev.loaded / ev.total) * 100
                  dispatch({
                    type: 'START_UPDATE',
                    payload: progress,
                  })
                },
              })
                .then((response) => {
                  const url = `https://cdn.kanguka.net/mp3/${file.name}`
                  payload['audio'] = url
                  payload['audioSize'] = file.size
                  payload['audioPath'] = file.name

                  // get shortUrl for audios
                  const opts = {
                    longDynamicLink: `https://kanguka.com/audio/?link=${url}`,
                    suffix: {
                      option: 'SHORT',
                    },
                  }

                  const shortlinkUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`
                  // get short URL
                  return fetch(shortlinkUrl, {
                    method: 'POST',
                    body: JSON.stringify(opts),
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                    .then((resp) => {
                      return resp.json()
                    })
                    .then((data) => {
                      payload['shortUrl'] = data.shortLink
                      payload['lastUpdatedOn'] = String(new Date().getTime())

                      return firebase
                        .ref(itemPath)
                        .update(payload)
                        .then(() => {
                          dispatch({
                            type: 'END_UPDATE',
                          })
                          fetchItems(path, lang)
                        })
                    })
                })
                .catch((err) => console.error(err))
            }
          } else {
            payload['lastUpdatedOn'] = String(new Date().getTime())
            if (payload.videoUrl && payload.genre === 'teaching-video') {
              // get shortUrl for videos
              const opts = {
                longDynamicLink: `https://kanguka.com/audio/?link=${payload.videoUrl}`,
                suffix: {
                  option: 'SHORT',
                },
              }

              const shortlinkUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`
              // get short URL
              return fetch(shortlinkUrl, {
                method: 'POST',
                body: JSON.stringify(opts),
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then((resp) => {
                  return resp.json()
                })
                .then((data) => {
                  payload['shortUrl'] = data.shortLink
                  payload['lastUpdatedOn'] = String(new Date().getTime())

                  return firebase
                    .ref(itemPath)
                    .update(payload)
                    .then(() => {
                      dispatch({
                        type: 'END_UPDATE',
                      })
                      fetchItems(path, lang)
                    })
                })
            } else {
              firebase
                .ref(itemPath)
                .update(payload)
                .then(() => {
                  dispatch({
                    type: 'END_UPDATE',
                  })
                  //
                  fetchItems(path, lang)
                })
            }
          }
        }
      })
    }
  }
}

export const deleteItem = (path, lang, id, filePath) => {
  return (dispatch, getState, {getFirebase}) => {
    // make async call
    const firebase = getFirebase()
    firebase
      .ref(`${path}/${lang}/${id}`)
      .remove()
      .then(() => {
        if (filePath) {
          // console.log('====================================')
          // console.log('delete me')
          // console.log('====================================')
          // create a reference to the file to delete
          const storageRef = firebase.storage().ref()
          var deleteRef = storageRef.child(filePath)

          // delete file from CDN
          if (path === 'programs') {
            const deleteUrl = `https://cdn.kanguka.net/api/delete?fileName=${filePath}`
            fetch(deleteUrl, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then((resp) => {
                return resp.json()
              })
              .then((data) => {
                // console.log(data)
              })
          } else {
            // delete file from Google cloud storage
            deleteRef
              .delete()
              .then(() => {
                // console.log(filePath + ' deleted successfully')
              })
              .catch((error) => {
                console.log(error)
              })
          }
        }
      })
  }
}

export const clearPrograms = () => {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_PROGRAMS',
    })
  }
}
