import React, {useState, forwardRef, useImperativeHandle} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ReactAudioPlayer from 'react-audio-player'
import ReactPlayer from 'react-player'

const AudioAudioPlayerDialog = forwardRef((props, ref) => {
  const [values, setValues] = useState({
    open: false,
    currentProgram: {},
  })

  const handleOpen = (payload) => {
    setValues({...values, open: true, currentProgram: payload})
  }

  const handleCancel = () => {
    setValues({...values, open: false})
  }

  useImperativeHandle(ref, () => ({
    openDialog(payload) {
      handleOpen(payload)
    },
  }))

  const MediaPreview = ({audio, video}) => {
    if (audio) {
      return <ReactAudioPlayer src={audio} autoPlay controls />
    }
    if (video) {
      return <ReactPlayer url={video} />
    }
    return <p>There is no audio file. Please upload a file.</p>
  }

  const isVideoContent = values.currentProgram.video !== null
  return (
    <Dialog
      open={values.open}
      onClose={handleCancel}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={isVideoContent ? 'xl' : 'xs'}
      aria-labelledby="confirmation-dialog-title">
      <DialogTitle id="confirmation-dialog-title">Audio Play</DialogTitle>
      <DialogContent dividers>
        <h4>{values.currentProgram.description}</h4>
        <MediaPreview
          audio={values.currentProgram.audio}
          video={values.currentProgram.video}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default AudioAudioPlayerDialog
