/* eslint-disable no-console */
const initState = {
  authError: null,
  isAuthenticated: false,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: 'Login failed',
      }
    case 'LOGIN_SUCESS':
      return {
        ...state,
        authError: null,
        isAuthenticated: true,
      }
    case 'SIGNOUT_SUCCESS':
      console.log('SIGNOUT_SUCCESS')
      return state
    case 'SIGNUP_SUCCESS':
      console.log('SIGNUP_SUCCESS')
      return {
        ...state,
        authError: null,
      }
    case 'SIGNUP_ERROR':
      console.log('SIGNUP_ERROR')
      return {
        ...state,
        authError: action.err.message,
      }

    default:
      return state
  }
}

export default authReducer
