import authReducer from './authReducer'
import crudReducer from './crudReducer'
import statsReducer from './statsReducer'
import {
  programReducer,
  categoryReducer,
  announcementReducer,
  galleryReducer,
} from './genericReducer'
import {combineReducers} from 'redux'

const rootReducer = combineReducers({
  auth: authReducer,
  program: programReducer,
  category: categoryReducer,
  announcement: announcementReducer,
  gallery: galleryReducer,
  crud: crudReducer,
  stats: statsReducer,
})

export default rootReducer
