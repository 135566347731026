import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ReactPlayer from 'react-player'

const PreviewDialog = forwardRef((props, ref) => {
  const [values, setValues] = useState({
    open: false,
    currentProgram: {}
  })

  const handleOpen = (payload) => {
    setValues({ ...values, open: true, currentProgram: payload })
  }

  const handleCancel = () => {
    setValues({ ...values, open: false })
  }

  useImperativeHandle(ref, () => ({
    openDialog(payload) {
      handleOpen(payload)
    }
  }))

  if (values.currentProgram.type === 'gallery') {
    return (
      <Dialog
        open={values.open}
        onClose={handleCancel}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="lg"
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">Gallery Preview</DialogTitle>
        <DialogContent dividers>
          <h4>{values.currentProgram.description}</h4>
          {values.currentProgram.photo ?
            <img src={values.currentProgram.photo} alt={values.currentProgram.description} style={{width: 800}}/> :
            <p>There is no photo. Please upload a photo.</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
          Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog
      open={values.open}
      onClose={handleCancel}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">Announcement preview</DialogTitle>
      <DialogContent dividers>
        <h4>{values.currentProgram.title}</h4>
        {values.currentProgram.type === 'video' ?
          <ReactPlayer controls={true} url={values.currentProgram.description} playing /> :
          null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
        Close
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default PreviewDialog
