/* eslint-disable no-alert */
/* eslint-disable no-console */
import axiosInstance from '../../config/api'
import jwtDecode from 'jwt-decode'

const currentPath = 'programs'
const apiToken = '29cf0cfd809938ec9dbfce164940ea05021e14a1'

const getPrefix = (path) => {
  let prefix
  switch (path) {
    case 'programs':
    case 'gallery':
      prefix = '-kang'
      break
    case 'categories':
      prefix = '-cat'
      break
    case 'announcements':
      prefix = '-anc'
      break
    default:
      prefix = ''
      break
  }
  return prefix
}

export const fetchPrograms = async (getAccessTokenSilently, dispatch, lang) => {
  try {
    const token = await getAccessTokenSilently()
    const payload = JSON.stringify({
      lang: lang.slice(0, 2),
      limit: 30,
    })
    const config = {
      method: 'post',
      url: '/api/v2/program/list',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    dispatch({
      type: `FETCH_${currentPath.toUpperCase()}_SUCCESS`,
      payload: responseData.data.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const createProgram = async (
  getAccessTokenSilently,
  dispatch,
  lang,
  payload,
) => {
  try {
    const key = String(new Date().getTime())
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)

    payload['firebaseId'] = `${getPrefix(currentPath)}${lang}${key}`
    payload['lastUpdatedBy'] = decoded.sub
    // Convert the existing date to ISO format
    const date = new Date(payload.date)
    payload['isoDate'] = date.toISOString()

    const data = JSON.stringify(payload)
    const config = {
      method: 'post',
      url: '/api/v2/program/create',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'CREATE_PROGRAM_SUCCESS',
    })
    fetchPrograms(getAccessTokenSilently, dispatch, lang)
  } catch (error) {
    console.log(error)
  }
}

export const editProgramWithFile = async (
  getAccessTokenSilently,
  dispatch,
  payload,
  lang,
) => {
  try {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)
    payload['lastUpdatedBy'] = decoded.sub
    // Convert the existing date to ISO format
    const date = new Date(payload.date)
    payload['isoDate'] = date.toISOString()

    const data = JSON.stringify(payload)
    const config = {
      method: 'put',
      url: `/api/v2/program/update/${payload.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'EDIT_PROGRAM_SUCCESS',
    })
    fetchPrograms(getAccessTokenSilently, dispatch, lang)
  } catch (error) {
    console.log(error)
  }
}
export const editProgram = async (
  getAccessTokenSilently,
  dispatch,
  lang,
  payload,
  file = null,
) => {
  const regex = new RegExp(/^[a-z0-9_\.]+(.mp3)$/)
  if (file) {
    if (!regex.test(file.name)) {
      alert('Filename is invalid. Please check again.')
    } else {
      const formData = new FormData()
      formData.append('file', file)
      const token = await getAccessTokenSilently()

      const config = {
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        url: '/api/v1/asset/upload',
        onUploadProgress: (ev) => {
          const progress = (ev.loaded / ev.total) * 100
          dispatch({
            type: 'START_UPDATE',
            payload: progress,
          })
        },
      }
      const response = await axiosInstance(config)
      const responseData = await response

      // console.log('===============result=====================')
      // console.log(responseData.data.url)
      // console.log('====================================')

      const url = `https://cdn.kanguka.info/${
        responseData.data.url.split('net/')[1]
      }`
      payload['audio'] = url
      payload['audioSize'] = file.size
      payload['audioPath'] = file.name

      // get shortUrl for audios
      const opts = {
        group_guid: 'Bl68iIMUDPu',
        domain: 'kanguka.co',
        long_url: url,
      }

      const shortlinkUrl = 'https://api-ssl.bitly.com/v4/shorten'
      // get short URL
      return fetch(shortlinkUrl, {
        method: 'POST',
        body: JSON.stringify(opts),
        headers: {
          Authorization: `Bearer ${apiToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          payload['shortUrl'] = data.link
          payload['lastUpdatedOn'] = String(new Date().getTime())
          editProgramWithFile(getAccessTokenSilently, dispatch, payload, lang)
        })
    }
  } else {
    try {
      const token = await getAccessTokenSilently()
      const decoded = jwtDecode(token)

      payload['lastUpdatedBy'] = decoded.sub
      // Convert the existing date to ISO format
      const date = new Date(payload.date)
      payload['isoDate'] = date.toISOString()

      const data = JSON.stringify(payload)
      const config = {
        method: 'put',
        url: `/api/v2/program/update/${payload.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      }

      // Send the initial PUT request
      const response = await axiosInstance(config)
      console.log(response.status) // Log the status of the response

      // Check if the additional POST request conditions are met
      if (
        payload.genre === 'kanguka' &&
        !/Samedi|Saturday|gatandatu/i.test(payload.description)
      ) {
        // Prepare the data for the POST request
        const postData = JSON.stringify({
          id: payload.id,
          date: payload.date,
          lang: payload.lang,
        })

        const postConfig = {
          method: 'post',
          url: `/api/v2/program/set-tag/${payload.id}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: postData,
        }

        // Send the additional POST request
        const postResponse = await axiosInstance(postConfig)
        console.log(postResponse.status)
      }

      // Dispatch success action
      dispatch({
        type: 'EDIT_PROGRAM_SUCCESS',
      })

      // Fetch updated programs list
      fetchPrograms(getAccessTokenSilently, dispatch, lang)
    } catch (error) {
      console.error(error) // Consider using console.error for logging errors
    }
  }
}

// deleteProgram(
//     getAccessTokenSilently,
//     dispatch,
//     state.lang,
//     payload.id,
//     payload.audioPath,
//   )

export const deleteProgram = async (
  getAccessTokenSilently,
  dispatch,
  lang,
  id,
  filePath,
) => {
  if (filePath) {
    console.log('================filePath====================')
    console.log(filePath)
    console.log('====================================')

    const token = await getAccessTokenSilently()

    const deleteUrl = `//dgpa7m9705i5r.cloudfront.net/${
      filePath.split('.info/')[1]
    }`

    const data = JSON.stringify({
      assetUrl: deleteUrl,
    })

    const config = {
      method: 'delete',
      url: '/api/v1/asset/delete',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)

    // fetch(deleteUrl, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // })
    //   .then((resp) => {
    //     return resp.json()
    //   })
    //   .then((data) => {
    //     // console.log(data)
    //   })
  }
  try {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)

    const data = JSON.stringify({
      id: id,
      lastUpdatedBy: decoded.sub,
    })
    const config = {
      method: 'delete',
      url: `/api/v2/program/delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'DELETE_PROGRAM_SUCCESS',
    })
    fetchPrograms(getAccessTokenSilently, dispatch, lang)
  } catch (error) {
    console.log(error)
  }
}
