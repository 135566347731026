import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'

// import {signOut} from '../../store/actions/authAction'
// import {clearPrograms} from '../../store/actions/genericActions'
import ProgramList from '../program/ProgramList'
import CategoryList from '../category/CategoryList'
import AnnouncementList from '../announcement/AnnouncementList'
import SidebarMenu from '../layout/Sidebar'
import GalleryList from '../gallery/GalleryList'
import StatisticsHome from '../statistics/StatisticsHome'
import StatisticsDevices from '../statistics/StatisticsDevices'
import StatisticsTimeline from '../statistics/StatisticsTimeline'
import SignIn from '../auth/SignIn'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#1F4DCC',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
}))

const MainApp = () => {
  const classes = useStyles()
  const {logout, isAuthenticated, isLoading} = useAuth0()

  const signOut = () => logout({returnTo: window.location.origin + '/signin'})

  const routes = [
    {
      path: '/',
      exact: true,
      main: () => <h2>Welcome to Kanguka Admin</h2>,
    },
    {
      path: '/programs',
      main: () => <ProgramList />,
    },
    {
      path: '/categories',
      main: () => <CategoryList />,
    },
    {
      path: '/announcements',
      main: () => <AnnouncementList />,
    },
    {
      path: '/gallery',
      main: () => <GalleryList />,
    },
    {
      path: '/website',
      main: () => <GalleryList />,
    },
    {
      path: '/statistics',
      main: () => <StatisticsHome />,
    },
    {
      path: '/statistics-devices',
      main: () => <StatisticsDevices />,
    },
    {
      path: '/statistics-timeline',
      main: () => <StatisticsTimeline />,
    },
  ]

  const BeforeLogin = () => {
    return <SignIn />
  }
  const AfterLogin = () => {
    return (
      <Router>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Link to="/" id="tohome">
                <Typography variant="h6" noWrap>
                  Kanguka Admin
                </Typography>
              </Link>
            </Toolbar>
          </AppBar>
          <SidebarMenu signOut={signOut} />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </main>
        </div>
      </Router>
    )
  }
  return (
    <>
      {isAuthenticated && !isLoading && <AfterLogin />}
      {!isAuthenticated && !isLoading && <BeforeLogin />}
    </>
  )
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     signOut: () => dispatch(signOut()),
//     clearPrograms: () => dispatch(clearPrograms())
//   }
// }

export default MainApp
