/* eslint-disable no-alert */
/* eslint-disable no-console */
import axiosInstance from '../../config/api'
import jwtDecode from 'jwt-decode'

const currentPath = 'categories'

const getPrefix = (path) => {
  let prefix
  switch (path) {
    case 'programs':
    case 'gallery':
      prefix = '-kang'
      break
    case 'categories':
      prefix = '-cat'
      break
    case 'announcements':
      prefix = '-anc'
      break
    default:
      prefix = ''
      break
  }
  return prefix
}

export const fetchCategories = async (
  getAccessTokenSilently,
  dispatch,
  lang,
) => {
  try {
    const token = await getAccessTokenSilently()
    const payload = JSON.stringify({
      lang: lang.slice(0, 2),
      // limit: 20,
    })
    const config = {
      method: 'post',
      url: '/api/v1/category/list',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    dispatch({
      type: `FETCH_${currentPath.toUpperCase()}_SUCCESS`,
      payload: responseData.data.reverse(),
    })
  } catch (error) {
    console.log(error)
  }
}

export const createCategory = async (
  getAccessTokenSilently,
  dispatch,
  lang,
  payload,
) => {
  try {
    const key = String(new Date().getTime())
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)

    payload['firebaseId'] = `${getPrefix(currentPath)}${lang}${key}`
    payload['lastUpdatedBy'] = decoded.sub

    const data = JSON.stringify(payload)
    const config = {
      method: 'post',
      url: '/api/v1/category/create',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'CREATE_CATEGORY_SUCCESS',
    })
    fetchCategories(getAccessTokenSilently, dispatch, lang)
  } catch (error) {
    console.log(error)
  }
}

export const editCategory = async (
  getAccessTokenSilently,
  dispatch,
  lang,
  payload,
) => {
  try {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)

    payload['lastUpdatedBy'] = decoded.sub

    const data = JSON.stringify(payload)
    const config = {
      method: 'put',
      url: `/api/v1/category/update/${payload.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'EDIT_CATEGORY_SUCCESS',
    })
    fetchCategories(getAccessTokenSilently, dispatch, lang)
  } catch (error) {
    console.log(error)
  }
}

export const deleteCategory = async (
  getAccessTokenSilently,
  dispatch,
  lang,
  id,
) => {
  try {
    const token = await getAccessTokenSilently()
    const decoded = jwtDecode(token)

    const data = JSON.stringify({
      id: id,
      lastUpdatedBy: decoded.sub,
    })
    const config = {
      method: 'delete',
      url: `/api/v1/category/delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    console.log(responseData.status)
    dispatch({
      type: 'DELETE_CATEGORY_SUCCESS',
    })
    fetchCategories(getAccessTokenSilently, dispatch, lang)
  } catch (error) {
    console.log(error)
  }
}
