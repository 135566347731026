import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import MainApp from './components/layout/MainApp'
import SignIn from './components/auth/SignIn'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={MainApp} />
          <Route path="/programs" component={MainApp} />
          <Route path="/categories" component={MainApp} />
          <Route path="/announcements" component={MainApp} />
          <Route path="/gallery" component={MainApp} />
          <Route path="/website" component={MainApp} />
          <Route path="/statistics" component={MainApp} />
          <Route path="/statistics-devices" component={MainApp} />
          <Route path="/statistics-timeline" component={MainApp} />
          <Route path="/signin" component={SignIn} />
        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default App
