/* eslint-disable no-alert */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
// import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import {Table} from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
// import Toolbar from '@material-ui/core/Toolbar'
// import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import Container from '@material-ui/core/Container'
import {useSelector} from 'react-redux'

const numeral = require('numeral')

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: 'unique_visitors',
    numeric: true,
    disablePadding: false,
    label: 'Visitors',
  },
  {
    id: 'hits',
    numeric: true,
    disablePadding: false,
    label: 'Hits',
  },
  {id: 'mp3_file', numeric: false, disablePadding: false, label: 'Audios'},
  {id: 'lang', numeric: false, disablePadding: false, label: 'Language'},
  {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
]

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isAudiosView,
    totalUniqueVisitors,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  if (isAudiosView) {
    return (
      <TableHead>
        <TableRow>
          {headCells
            .filter((cell) => cell.id !== 'lang' && cell.id !== 'mp3_file')
            .map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.id === 'unique_visitors'
                    ? `${headCell.label} (Total ${numeral(
                        totalUniqueVisitors,
                      ).format('0,0')})`
                    : headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.id === 'unique_visitors'
                ? `${headCell.label} (Total ${numeral(
                    totalUniqueVisitors,
                  ).format('0,0')})`
                : headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  isAudiosView: PropTypes.bool,
  totalUniqueVisitors: PropTypes.number,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}))

export default function EnhancedTable(props) {
  const {isAudiosView} = props
  const classes = useStyles()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('unique_visitors')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [rows, setRows] = useState([])

  const dateAnalytics = useSelector((state) => state.stats.analytics)
  const audioAnalytics = useSelector((state) => state.stats.audioAnalytics)
  const isLoading = useSelector((state) => state.stats.isLoading)

  const parseData = (input) => {
    input.forEach((element) => {
      if (element.mp3_file.indexOf('_fr.mp3') > -1) {
        element['lang'] = 'FR'
      }
      if (element.mp3_file.indexOf('_ki.mp3') > -1) {
        element['lang'] = 'KI'
      }
      if (element.mp3_file.indexOf('_en.mp3') > -1) {
        element['lang'] = 'EN'
      }
      element.mp3_file = element.mp3_file.split('/')[4]
    })
    return input
  }

  useEffect(() => {
    if (isAudiosView && audioAnalytics && audioAnalytics.length > 0) {
      setRows(audioAnalytics)
    } else if (!isAudiosView && dateAnalytics && dateAnalytics.length > 0) {
      const parsed = parseData(dateAnalytics)
      setRows(parsed)
    }
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <LinearProgress />
          <br />
          <p>Loading.... Please wait</p>
          <br />
          <LinearProgress color="secondary" />
        </Container>
      </div>
    )

    // return <CircularProgress className={classes.number} color="secondary" />
  }

  const totalUniqueVisitors = rows.reduce(function (acc, obj) {
    return acc + obj.unique_visitors
  }, 0)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              isAudiosView={isAudiosView}
              totalUniqueVisitors={totalUniqueVisitors}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name)
                  const labelId = `enhanced-table-checkbox-${index}`

                  if (isAudiosView) {
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.mp3_file}
                        selected={isItemSelected}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="right">
                          {numeral(row.unique_visitors).format('0,0')}
                        </TableCell>
                        <TableCell align="right">
                          {numeral(row.hits).format('0,0')}
                        </TableCell>
                        <TableCell align="left">{row.date}</TableCell>
                      </TableRow>
                    )
                  }
                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.mp3_file}
                      selected={isItemSelected}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="right">
                        {numeral(row.unique_visitors).format('0,0')}
                      </TableCell>
                      <TableCell align="right">
                        {numeral(row.hits).format('0,0')}
                      </TableCell>
                      <TableCell align="left">{row.mp3_file}</TableCell>
                      <TableCell align="left">{row.lang}</TableCell>
                      <TableCell align="left">{row.date}</TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
