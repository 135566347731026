import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  chart: {
    height: '100%',
  },
  textField: {
    marginTop: 0,
  },
  paper: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    height: '90px',
    marginBottom: theme.spacing(1),
  },
  number: {
    marginTop: '30px',
    fontWeight: 'bold',
  },
  margin: {
    textTransform: 'none',
  },
  table: {
    paddingTop: theme.spacing(2),
  },
  langTitle: {
    width: '100px',
    background: 'rgba(76, 175, 80, 0.1)',
    borderRadius: '3px',
    color: 'rgb(76, 175, 80)',
  },
}))
