const initState = {
  loading: 0
}

const crudReducer = (state = initState, action) => {
  switch (action.type) {
    case 'START_UPDATE':
      return {
        ...state,
        loading: action.payload
      }

    case 'END_UPDATE':
      return {
        ...state,
        loading: 100
      }
    default:
      return state
  }
}

export default crudReducer
