/* eslint-disable no-console */
const initState = []

const parseData = (data) => {
  let ret = []
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const element = data[key]
      element['id'] = key
      ret.push(element)
    }
  }
  return ret.reverse()
}
const programReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_PROGRAMS_SUCCESS':
      console.log('FETCH_PROGRAMS_SUCCESS')
      return action.payload

    case 'CLEAR_PROGRAMS':
      console.log('CLEAR_PROGRAMS')
      return initState
    default:
      return state
  }
}

const categoryReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES_SUCCESS':
      console.log('FETCH_CATEGORIES_SUCCESS')
      return parseData(action.payload)

    default:
      return state
  }
}

const announcementReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_ANNOUNCEMENTS_SUCCESS':
      console.log('FETCH_ANNOUNCEMENTS_SUCCESS')
      return parseData(action.payload)

    default:
      return state
  }
}

const galleryReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_GALLERY_SUCCESS':
      console.log('FETCH_GALLERY_SUCCESS')
      return parseData(action.payload)

    default:
      return state
  }
}

export {programReducer, categoryReducer, announcementReducer, galleryReducer}
