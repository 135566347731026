import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import MaterialTable, {MTableToolbar} from 'material-table'
import {makeStyles} from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import {useAuth0} from '@auth0/auth0-react'

import CategoryEdit from './CategoryEdit'
import ConfirmDialog from '../modals/ConfirmDialog'
import AudioPlayerDialog from '../modals/AudioPlayerDialog'
import Flag from '../layout/Flag'

import {
  createCategory,
  deleteCategory,
  editCategory,
  fetchCategories,
} from './CategoryServices'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  flag: {
    marginRight: theme.spacing(5),
  },
}))
// const currentPath = 'categories'

const CategoryList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {getAccessTokenSilently} = useAuth0()

  const editRef = useRef()
  const dialogRef = useRef()
  const playerRef = useRef()

  const [state, setState] = useState({
    lang: 'kirundi',
    selectedRow: null,
    columns: [
      {title: 'Description', field: 'description'},
      {
        field: '1Start',
        title: 'Start',
        render: (rawData) => (
          <span>{moment(rawData['1Start']).format('DD/MM/YYYY HH:mm')}</span>
        ),
      },
      {
        field: '2End',
        title: 'End',
        render: (rawData) => (
          <span>{moment(rawData['2End']).format('DD/MM/YYYY HH:mm')}</span>
        ),
      },
      {
        field: 'status',
        title: 'Status',
        render: (rawData) =>
          rawData.status === 'online' ? (
            <span style={{color: 'green'}}>{rawData.status}</span>
          ) : (
            <span>{rawData.status}</span>
          ),
      },
    ],
  })

  const categories = useSelector((st) => st.category)
  const loading = useSelector((st) => st.crud.loading)

  useEffect(() => {
    fetchCategories(getAccessTokenSilently, dispatch, state.lang)
  }, [state])

  const handleClickOpen = (payload) => {
    editRef.current.openDialog(payload)
  }

  const createNew = () => {
    const newCategory = {
      description: 'new category ' + state.lang,
      '1Start': moment(new Date()).format('YYYY-MM-DD HH:mm'),
      '2End': moment(new Date()).format('YYYY-MM-DD HH:mm'),
      status: 'offline',
      lang: state.lang.slice(0, 2),
    }
    createCategory(getAccessTokenSilently, dispatch, state.lang, newCategory)
  }

  const handleDeleteConfirm = (payload) => {
    deleteCategory(getAccessTokenSilently, dispatch, state.lang, payload.id)
  }

  const handleEditConfirm = (payload) => {
    editCategory(getAccessTokenSilently, dispatch, state.lang, payload)
  }

  const handleLangChange = (event) => {
    setState({...state, lang: event.target.value})
  }

  if (!categories || categories.length === 0) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <LinearProgress />
          <br />
          <p>Loading.... Please wait</p>
          <br />
          <LinearProgress color="secondary" />
        </Container>
      </div>
    )
  }

  if (loading > 0 && loading < 100) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <p>File uploading.... Please wait {parseInt(Number(loading), 10)}%</p>
          <progress id="uploader" value={loading} max="100"></progress>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <MaterialTable
        className={classes.content}
        title={'Categories'}
        columns={state.columns}
        data={categories}
        onRowClick={(evt, selectedRow) => {
          setState({...state, selectedRow})
          handleClickOpen(selectedRow)
        }}
        components={{
          Toolbar: (tableProps) => (
            <div>
              <MTableToolbar {...tableProps} />
              <div style={{padding: '0 0 0 10px'}}>
                <FormControl className={classes.formControl}>
                  <Select value={state.lang} onChange={handleLangChange}>
                    <MenuItem value={'kirundi'}> Kirundi </MenuItem>
                    <MenuItem value={'francais'}> Français </MenuItem>
                    <MenuItem value={'english'}> English </MenuItem>
                  </Select>
                </FormControl>
                <IconButton className={classes.flag} disabled>
                  <Flag lang={state.lang} />
                </IconButton>
                <IconButton
                  aria-label="Add"
                  color="primary"
                  onClick={createNew}>
                  <Icon color="primary">add_circle</Icon>
                </IconButton>
              </div>
            </div>
          ),
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Delete Category',
            onClick: (event, rowData) => {
              dialogRef.current.openDialog({
                id: rowData._id,
                description: rowData.description,
                audioPath: rowData.audioPath,
              })
            },
          },
        ]}
        options={{
          pageSize: 10,
          rowStyle: (rowData) => ({
            backgroundColor:
              state.selectedRow &&
              state.selectedRow.tableData.id === rowData.tableData.id
                ? '#EEE'
                : '#FFF',
          }),
          headerStyle: {
            backgroundColor: '#fafafa',
            paddingLeft: '15px',
          },
        }}
      />
      <CategoryEdit ref={editRef} handleConfirm={handleEditConfirm} />
      <ConfirmDialog ref={dialogRef} handleConfirm={handleDeleteConfirm} />
      <AudioPlayerDialog ref={playerRef} />
    </div>
  )
}

export default CategoryList
