import React, {forwardRef, useImperativeHandle} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  menu: {
    width: 200,
  },
}))

const status = [
  {
    value: 'offline',
    label: 'Offline',
  },
  {
    value: 'online',
    label: 'Online',
  },
]

const mode = [
  {
    value: 'gallery',
    label: 'Gallery',
  },
  {
    value: 'homepage',
    label: 'Home page',
  },
  {
    value: 'aboutpage',
    label: 'About page',
  },
]

const GalleryEdit = forwardRef((props, ref) => {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    open: false,
  })

  const handleChange = (name) => (event) => {
    setValues({...values, [name]: event.target.value})
  }

  const handleClose = () => {
    setValues({...values, open: false})
  }

  const handleOpen = (payload) => {
    setValues({
      ...values,
      description: payload.description,
      photo: payload.photo,
      status: payload.status,
      mode: payload.mode,
      id: payload._id,
      open: true,
    })
  }

  const handleConfirm = () => {
    props.handleConfirm(values, document.getElementById('audioFile').files[0])
    setValues({...values, open: false})
  }

  useImperativeHandle(ref, () => ({
    openDialog(payload) {
      handleOpen(payload)
    },
  }))

  return (
    <Dialog
      open={values.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
      <DialogContent>
        <form className={classes.container} autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TextField
              id="description"
              label="Description"
              style={{margin: 8}}
              fullWidth
              margin="normal"
              value={values.description}
              onChange={handleChange('description')}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              disabled
              id="photo"
              label="Photo"
              style={{margin: 8}}
              fullWidth
              margin="normal"
              value={values.photo}
              onChange={handleChange('photo')}
              InputLabelProps={{
                shrink: true,
                readOnly: true,
              }}
            />
            <Input id="audioFile" fullWidth type="file" />
            <TextField
              id="status"
              select
              label="Status"
              className={classes.textField}
              value={values.status}
              onChange={handleChange('status')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal">
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="mode"
              select
              label="Mode"
              className={classes.textField}
              value={values.mode}
              onChange={handleChange('mode')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal">
              {mode.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </MuiPickersUtilsProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default GalleryEdit
