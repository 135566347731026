import React, {useState, forwardRef, useImperativeHandle} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmDialog = forwardRef((props, ref) => {
  const [values, setValues] = useState({
    open: false,
    currentProgram: {},
  })

  const handleCancel = () => {
    setValues({...values, open: false})
  }

  const handleOpen = (payload) => {
    console.log('==============handleOpen======================')
    console.log(payload)
    console.log('====================================')
    setValues({...values, open: true, currentProgram: payload, id: payload._id})
  }

  const handleConfirm = () => {
    props.handleConfirm(values.currentProgram)
    setValues({...values, open: false})
  }

  useImperativeHandle(ref, () => ({
    openDialog(payload) {
      handleOpen(payload)
    },
  }))

  return (
    <Dialog
      open={values.open}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title">
      <DialogTitle id="confirmation-dialog-title">
        Are you sure you want to delete?
      </DialogTitle>
      <DialogContent dividers>
        {values.currentProgram.description}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ConfirmDialog
