import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import MaterialTable, {MTableToolbar} from 'material-table'
import {makeStyles} from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'
import moment from 'moment'
import {useAuth0} from '@auth0/auth0-react'

import {
  createPicture,
  deletePicture,
  editPicture,
  fetchPictures,
} from './GalleryServices'

import GalleryEdit from './GalleryEdit'
import ConfirmDialog from '../modals/ConfirmDialog'
import PreviewDialog from '../modals/PreviewDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  flag: {
    marginRight: theme.spacing(5),
  },
}))

const GalleryList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {getAccessTokenSilently} = useAuth0()

  const editRef = useRef()
  const dialogRef = useRef()
  const playerRef = useRef()

  const [state, setState] = useState({
    lang: 'items',
    selectedRow: null,
    columns: [
      {title: 'Description', field: 'description'},
      {title: 'Mode', field: 'mode'},
      {
        field: 'photo',
        title: 'Photo',
        render: (rawData) => (
          <img
            src={rawData.photo}
            alt={rawData.genre}
            style={{width: 120, borderRadius: '1%'}}
          />
        ),
      },
      {
        field: 'status',
        title: 'Status',
        render: (rawData) =>
          rawData.status === 'online' ? (
            <span style={{color: 'green'}}>{rawData.status}</span>
          ) : (
            <span>{rawData.status}</span>
          ),
      },
    ],
  })

  const galleries = useSelector((st) => st.gallery)
  const loading = useSelector((st) => st.crud.loading)

  useEffect(() => {
    fetchPictures(getAccessTokenSilently, dispatch)
  }, [state])

  const handleClickOpen = (payload) => {
    editRef.current.openDialog(payload)
  }

  const createNew = () => {
    const newPhoto = {
      description: 'new photo ',
      photo: '',
      date: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      status: 'offline',
    }
    createPicture(getAccessTokenSilently, dispatch, newPhoto)
  }

  const handleDeleteConfirm = (payload) => {
    deletePicture(getAccessTokenSilently, dispatch, payload.id, payload.audio)
  }

  const handleEditConfirm = (payload, file) => {
    editPicture(getAccessTokenSilently, dispatch, payload, file)
  }

  if (!galleries || galleries.length === 0) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <LinearProgress />
          <br />
          <p>Loading.... Please wait</p>
          <br />
          <LinearProgress color="secondary" />
        </Container>
      </div>
    )
  }

  if (loading > 0 && loading < 100) {
    return (
      <div className={classes.loadingContainer}>
        <Container fixed>
          <p>File uploading.... Please wait {parseInt(Number(loading), 10)}%</p>
          <progress id="uploader" value={loading} max="100"></progress>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <MaterialTable
        className={classes.content}
        title={'Gallery'}
        columns={state.columns}
        data={galleries}
        onRowClick={(evt, selectedRow) => {
          setState({...state, selectedRow})
          handleClickOpen(selectedRow)
        }}
        components={{
          Toolbar: (tableProps) => (
            <div>
              <MTableToolbar {...tableProps} />
              <div style={{padding: '0 0 0 10px'}}>
                <IconButton
                  aria-label="Add"
                  color="primary"
                  onClick={createNew}>
                  <Icon color="primary">add_circle</Icon>
                </IconButton>
              </div>
            </div>
          ),
        }}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Delete Photo',
            onClick: (event, rowData) => {
              dialogRef.current.openDialog({
                id: rowData._id,
                description: rowData.description,
              })
            },
          },
          {
            icon: 'shop',
            tooltip: 'Preview Photo',
            onClick: (event, rowData) => {
              playerRef.current.openDialog({
                description: rowData.description,
                photo: rowData.photo,
                type: 'gallery',
              })
            },
          },
        ]}
        options={{
          pageSize: 10,
          rowStyle: (rowData) => ({
            backgroundColor:
              state.selectedRow &&
              state.selectedRow.tableData.id === rowData.tableData.id
                ? '#EEE'
                : '#FFF',
          }),
          headerStyle: {
            backgroundColor: '#fafafa',
          },
        }}
      />
      <GalleryEdit ref={editRef} handleConfirm={handleEditConfirm} />
      <ConfirmDialog ref={dialogRef} handleConfirm={handleDeleteConfirm} />
      <PreviewDialog ref={playerRef} />
    </div>
  )
}

export default GalleryList
