import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { fetchDevices } from '../../store/actions/statsActions'

const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  flag: {
    marginRight: theme.spacing(5)
  }
})

class StatisticsDevices extends Component {

  state = {
    data: [],
    currentCountry: 'BI',
    countriesLookup: {'BI': 'Burundi'}
  }

  fetchDataByCountry(country, lookup = false) {
    this.props.fetchDevices(country, lookup)
  }

  componentDidMount() {
    this.fetchDataByCountry(this.state.currentCountry, true)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.stats !== this.props.stats) {
      this.setState({
        ...this.state,
        data: nextProps.stats.data,
        title: nextProps.stats.title,
        subtitle: nextProps.stats.subtitle,
        countriesLookup: nextProps.stats.countriesLookup ? nextProps.stats.countriesLookup : this.state.countriesLookup,
        currentCountry: nextProps.stats.countryCode
      })
    }
  }

  render() {
    const { classes } = this.props
    const { currentCountry, data, countriesLookup, subtitle } = this.state
    const handleCountryChange = (event) => {
      this.fetchDataByCountry(event.target.value, false)
    }

    const options = {
      chart: {
        type: "spline",
        zoomType: 'x',
        height: (9 / 16 * 100) + '%', // 16:9 ratio,
        style: {
          fontFamily: 'inherit'
        }
      },
      title: {
        text: `Kanguka Apps by devices in ${countriesLookup[currentCountry]}`,
      },
      subtitle: {
        text: subtitle
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Number of installation'
        }
      },
      legend: {
        enabled: true
      },
      series: data
    }

    if (Object.keys(data).length === 0) {
      return (
        <div className={classes.loadingContainer}>
          <Container fixed>
            <LinearProgress />
            <br />
            <p>Loading.... Please wait</p>
            <br />
            <LinearProgress color="secondary"/>
          </Container>
        </div>
      )
    }

    return (
      <Paper className={classes.root}>
        <div style={{padding: '0 0 0 10px'}}>
          <FormControl className={classes.formControl}>
            <Select
              value={currentCountry}
              onChange={handleCountryChange}
            >
              {
                Object.keys(this.state.countriesLookup).sort().map((country, index) => (
                  <MenuItem key={index} value={country}> {this.state.countriesLookup[country]} </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <span style={{marginTop: '18px'}} className={`flag-icon flag-icon-${currentCountry.toLowerCase()}`}></span>
        </div>
        <Typography component="div" style={{ height: 'calc(100vh - 150px)', width: '100%' }}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Typography>
      </Paper>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    stats: state.stats.devices
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDevices: (country, lookup) => dispatch(fetchDevices(country, lookup))
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles, { withTheme: true }))(StatisticsDevices)

