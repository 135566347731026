import React, { forwardRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  menu: {
    width: 200,
  },
}))

const status = [
  {
    value: 'offline',
    label: 'Offline'
  },
  {
    value: 'online',
    label: 'Online'
  }
]
const types = [
  {
    value: 'text',
    label: 'Text'
  },
  {
    value: 'video',
    label: 'Video'
  }
]

const AnnouncementEdit = forwardRef((props, ref) => {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    open: false
  })

  const handleChange = name => event => {
    if (name === 'date') {
      const newDateTime = moment(event).format("YYYY-MM-DD HH:mm")
      setValues({ ...values, [name]: newDateTime })
    } else {
      setValues({ ...values, [name]: event.target.value })
    }
  }

  const handleClose = () => {
    setValues({ ...values, open: false })
  }

  const handleOpen = (payload) => {
    setValues({ ...values,
      title: payload.title,
      description: payload.description,
      date: moment(payload.date).format("YYYY-MM-DD HH:mm"),
      type: payload.type,
      status: payload.status,
      id: payload.id,
      open: true
    })
  }

  const handleConfirm = () => {
    props.handleConfirm(values)
    setValues({ ...values, open: false })
  }

  useImperativeHandle(ref, () => ({
    openDialog(payload) {
      handleOpen(payload)
    }
  }))

  return (
    <Dialog open={values.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit</DialogTitle>
      <DialogContent>
        <form className={classes.container} autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TextField
              id="title"
              label="Title"
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              value={values.title}
              onChange={handleChange('title')}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="description"
              label="Description"
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              value={values.description}
              onChange={handleChange('description')}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date"
              label="Date"
              format="dd/MM/yyyy"
              value={values.date}
              className={classes.textField}
              onChange={handleChange('date')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time"
              label="Time"
              value={values.date}
              className={classes.textField}
              onChange={handleChange('date')}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
            <TextField
              id="type"
              select
              label="Type"
              className={classes.textField}
              value={values.type}
              onChange={handleChange('type')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {types.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="status"
              select
              label="Status"
              className={classes.textField}
              value={values.status}
              onChange={handleChange('status')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {status.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </MuiPickersUtilsProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
            Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
)

export default AnnouncementEdit
