/* eslint-disable no-alert */
/* eslint-disable no-console */
import axiosInstance from '../../config/api'

export const fetchAnalyticsByDate = async (
  getAccessTokenSilently,
  dispatch,
  params,
) => {
  try {
    const token = await getAccessTokenSilently()
    const payload = JSON.stringify({
      date: params,
    })
    dispatch({
      type: 'FETCH_START',
    })
    const config = {
      method: 'post',
      url: '/api/v1/analytic/audiodate',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    return dispatch({
      type: 'FETCH_STATS_SUCCESS',
      payload: responseData.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const fetchAnalyticsByAudio = async (
  getAccessTokenSilently,
  dispatch,
  params,
) => {
  try {
    const token = await getAccessTokenSilently()
    const payload = JSON.stringify({
      audio: params,
    })
    dispatch({
      type: 'FETCH_START',
    })
    const config = {
      method: 'post',
      url: '/api/v1/analytic/audiodate',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }
    const response = await axiosInstance(config)
    const responseData = await response
    return dispatch({
      type: 'FETCH_STATS_BY_AUDIO_SUCCESS',
      payload: responseData.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const fetchAudios = async (getAccessTokenSilently, dispatch) => {
  try {
    const token = await getAccessTokenSilently()
    dispatch({
      type: 'FETCH_START',
    })
    const config = {
      method: 'get',
      url: '/api/v1/analytic/audios',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
    const response = await axiosInstance(config)
    const responseData = await response
    return dispatch({
      type: 'FETCH_AUDIO_SUCCESS',
      payload: responseData.data,
    })
  } catch (error) {
    console.log(error)
  }
}
