import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Flag = (props) => {
  if (props.lang === 'francais') {
    return (
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icon-css-fr"
          viewBox="0 0 640 480">
          <g fillRule="evenodd" strokeWidth="1pt">
            <path fill="#fff" d="M0 0h640v480H0z" />
            <path fill="#00267f" d="M0 0h213.3v480H0z" />
            <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
          </g>
        </svg>
      </SvgIcon>
    )
  } else if (props.lang === 'kirundi') {
    return (
      <SvgIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icon-css-bi"
          viewBox="0 0 640 480">
          <defs>
            <clipPath id="a">
              <path fillOpacity=".7" d="M-90.5 0H592v512H-90.5z" />
            </clipPath>
          </defs>
          <g
            fillRule="evenodd"
            clipPath="url(#a)"
            transform="translate(84.9) scale(.9375)">
            <path
              fill="#00cf00"
              d="M-178 0l428.8 256L-178 512zm857.6 0L250.8 256l428.8 256z"
            />
            <path
              fill="red"
              d="M-178 0l428.8 256L679.6 0zm0 512l428.8-256 428.8 256z"
            />
            <path
              fill="#fff"
              d="M679.6 0h-79.9L-178 464.3V512h79.9L679.6 47.7z"
            />
            <path
              fill="#fff"
              d="M398.9 256a148 148 0 11-296.1 0 148 148 0 01296 0z"
            />
            <path fill="#fff" d="M-178 0v47.7L599.7 512h79.9v-47.7L-98.1 0z" />
            <path
              fill="red"
              stroke="#00de00"
              strokeWidth="3.9"
              d="M280 200.2l-19.3.3-10 16.4-9.9-16.4-19.2-.4 9.3-16.9-9.2-16.8 19.2-.4 10-16.4 9.9 16.5 19.2.4-9.3 16.8zm-64.6 111.6l-19.2.3-10 16.4-9.9-16.4-19.2-.4 9.3-16.9-9.2-16.8 19.2-.4 10-16.4 9.9 16.5 19.2.4-9.3 16.8zm130.6 0l-19.2.3-10 16.4-10-16.4-19.1-.4 9.3-16.9-9.3-16.8 19.2-.4 10-16.4 10 16.5 19.2.4-9.4 16.8z"
            />
          </g>
        </svg>
      </SvgIcon>
    )
  } else {
    return (
      <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
          <path fill="#012169" d="M0 0h640v480H0z" />
          <path
            fill="#FFF"
            d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"
          />
          <path
            fill="#C8102E"
            d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"
          />
          <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
          <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
        </svg>
      </SvgIcon>
    )
  }
}

export default Flag
